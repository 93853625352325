// ** React Imports
import { useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'
import { useUser } from '@clerk/nextjs'
import { RotateLeft } from 'mdi-material-ui'

// ** Hook Imports

const Home = () => {
  // ** Hooks
  const { isLoaded, user, isSignedIn } = useUser()

  const router = useRouter()

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    router.replace('/home')
    // we only want this to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Home
